<template>
    <div class="px-1" v-loading="isLoading">
      <b-row style="flex-wrap: initial;">
        <!-- column-one -->
        <b-col class="mb-30" style="flex: 1 1 0%; padding: 0;">
          <div class="px-1">
            <div class="d-flex mb-1">
              <p class="card-title mr-2"><i class="el-icon-office-building"></i> {{ organization.name }} <span v-if="organization.status === 'PENDING'">({{ organization.status }})</span></p>
              <span class="mr-2"><i class="el-icon-alarm-clock"></i> {{$t('Created')}} {{ dealSelected.add_time | formatTimestamp }}</span>
              <span class="mr-2"><i class="el-icon-alarm-clock"></i> {{$t('Updated')}} {{ dealSelected.update_time | formatTimestamp }}</span>
            </div>
            <div class="d-flex mb-1">
              <p class="card-title m-0">
                <span class="mr-2" v-if="creator.name"><i class="el-icon-user-solid"></i> {{$t('Creator')}}: {{ creator.name }}</span>
                <span class="mr-2" v-if="owner.name"><i class="el-icon-user-solid"></i> {{$t('Owner')}}: {{ owner.name }}</span>
                <span class="mr-2" v-if="dealSelected.internal_referral_user"><i class="el-icon-user-solid"></i> {{$t('Referral')}}: {{ dealSelected.internal_referral_type }} - {{ dealSelected.internal_referral_user }}</span>
              </p>
            </div>
            <div class="d-flex mb-1">
              <span style="margin-right:10px;" v-if="isPreview"><i class="el-icon-takeaway-box"></i> {{ dealSelected.product }}</span>
              <div>
                <span class="mr-2" v-if="contact.name"><i class="el-icon-user"></i> {{ contact.name }}</span>
                <span class="mr-2" v-if="contact.phone"><i class="el-icon-phone"></i> <a :href="'tel:' + contact.phone">{{ contact.phone }}</a></span>
                <span class="mr-2" v-if="contact.email"><i class="el-icon-message"></i> <a :href="'mailto:' + contact.email">{{ contact.email }}</a></span>
              </div>
            </div>
            <div class="d-flex mb-1">
              <p class="card-title m-0"><i class="el-icon-wallet"></i> Monthly Total: {{ dealSelected.currency }} {{ dealSelected.value | formatTotalPrice }}</p>
              <p class="card-title m-0 ml-2"><i class="el-icon-wallet"></i> Annual Total: {{ dealSelected.currency }} {{ dealSelected.value * 12 | formatTotalPrice }}</p>
            </div>
          </div>
        </b-col>
      </b-row>

      <table style="width: 100%; margin-bottom: 16px; font-size: 12px;">
        <tr style="border-bottom: 1px solid #ccc;">
          <th>{{$t('Product Code')}}</th>
          <th>{{$t('Origin')}}</th>
          <th>{{$t('Destination')}}</th>
          <th>{{$t('Product Type')}}</th>
          <th>{{$t('Unit Rate')}}</th>
          <th>{{$t('No. of Parcel/Month')}}</th>
          <th>{{$t('Amount')}}</th>
          <th>{{$t('Currency')}}</th>
        </tr>
        <tr v-for="(product, k) in dealSelected.products" :key="k">
          <td>
            {{ product.code }}
          </td>
          <td>
            {{ product.origin }}
          </td>
          <td>
            {{ product.destination }}
          </td>
          <td>
            {{ product.product_type }}
          </td>
          <td>
            {{ product.price | formatTotalPrice }}
          </td>
          <td>
            {{ product.qty }}
          </td>
          <td>
            {{ product.line_total | formatTotalPrice }}
          </td>
          <td>
            {{ product.currency }}
          </td>
        </tr>
      </table>

      <div class="py-1">
        <el-button type="primary" v-if="dealSelected.status === 'ACTIVE'" style="background: #ea5455; color: #fff; border: 1px solid #ea5455; float: right; margin-left: 20px;" v-b-modal.modal-lost>{{$t('Lost')}}</el-button>
        <el-button type="primary" v-if="dealSelected.status === 'ACTIVE'" style="background: #28c76f; color: #fff; border: 1px solid #28c76f; float: right; margin-left: 20px;" v-b-modal.modal-won>{{$t('Won')}}</el-button>
        <el-button type="primary" v-if="dealSelected.status !== 'ACTIVE'" style="background: #ff9f43; color: #fff; border: 1px solid #ff9f43; float: right; margin-left: 20px;" v-b-modal.modal-reopen>{{$t('Reopen')}}</el-button>
        <span class="clearfix"></span>
      </div>

      <el-form>
        <b-form-textarea
          id="textarea"
          v-model="note"
          :placeholder="$t('Take a note...')"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <div class="note-type-container">
          <span>{{$t('Note types')}}:</span>
            <span v-for="list in lists" :key="list.id" class="note-option">
            <input type="radio" :value="list.val" :id="'note-' + list.val" v-model="noteType" />
            <label :for="'note-' + list.val">{{ list.item }}</label>
				  </span>
          <el-button
            type="primary"
            size="small"
            style="float:right; margin-top: 20px; width: 150px;"
            @click="submitNote"
          >{{$t('Save')}}
          </el-button>
        </div>
      </el-form>
      <h3>{{$t('Notes')}}</h3>
      <div style="max-height: 50vh; overflow-y: auto">
        <table>
          <tr v-if="histories.length === 0">
            <td>{{$t('Nothing yet')}}...</td>
          </tr>
          <tr v-for="(history, k) in histories" :key="k">
            <td>
              <feather-icon icon="PhoneIcon" size="18" class="text-muted" v-if="history.action === 'NOTE_PHONE'"/>
              <feather-icon icon="MailIcon" size="18" class="text-muted" v-if="history.action === 'NOTE_EMAIL'"/>
              <feather-icon icon="UsersIcon" size="18" class="text-muted" v-if="history.action === 'NOTE_MEETING'"/>
              <feather-icon icon="RefreshCwIcon" size="18" class="text-muted" v-if="history.action === 'ACTIVE'"/>
              <feather-icon icon="CheckCircleIcon" size="18" class="text-muted" v-if="history.action === 'WON'"/>
              <feather-icon icon="MinusCircleIcon" size="18" class="text-muted" v-if="history.action === 'LOST'"/>
              <feather-icon icon="ArrowRightIcon" size="18" class="text-muted" v-if="history.action === 'MOVE'"/>
              <feather-icon icon="AlertTriangleIcon" size="18" class="text-muted" v-if="history.action === 'INACTIVE'"/>
              <feather-icon icon="TrashIcon" size="18" class="text-muted" v-if="history.action === 'DELETE'"/>
            </td>
            <td scope="row">
              <div class="contentContainer">
                <div>
                  <span v-if="history.add_time">{{ history.add_time | formatTimestamp }}</span>
                  <span v-if="history.user_id" class="ml-2"><i class="el-icon-user"></i> {{ getUsername(history.user_id) }}</span>
                </div>
                <div v-if="history.action === 'INACTIVE'" style="color: red;">{{ history.message }}</div>
                <div v-else>{{ history.message }}</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <b-modal id="modal-won" hide-footer size="sm" centered :title="$t('Change to Won Stage')">
        <div>
          <b-row class="justify-content-center">
            <feather-icon
              icon="AlertCircleIcon"
              size="50"
              class="text-muted"
              color="red"
            />
          </b-row>
          <b-row class="justify-content-center text-center">
            <b-col>{{$t('Are you sure to proceed')}}?</b-col>
          </b-row>
          <b-row class="justify-content-around" style="margin-top: 15px;">
            <b-button size="sm" variant="danger" @click="cancelWon()">{{$t('Cancel')}}</b-button>
            <b-button size="sm" variant="success" @click="submitWon()">{{$t('Confirm')}}</b-button>
          </b-row>
        </div>
      </b-modal>
      <b-modal id="modal-lost" hide-footer size="sn" centered :title="$t('Change to Lost Stage')">
        <div>
          <b-row class="justify-content-center">
            <feather-icon
              icon="AlertCircleIcon"
              size="50"
              class="text-muted"
              color="red"
            />
          </b-row>
          <b-row class="justify-content-center text-center">
            <b-col>{{$t('Are you sure to proceed')}}?</b-col>
          </b-row>
          <b-row>
            <input class="form-control" type="text" v-model="lostReason" placeholder="Enter lost reason..."/>
          </b-row>
          <b-row class="justify-content-around" style="margin-top: 15px;">
            <b-button size="sm" variant="danger" @click="cancelLost()">{{$t('Cancel')}}</b-button>
            <b-button size="sm" variant="success" @click="submitLost()">{{$t('Confirm')}}</b-button>
          </b-row>
        </div>
      </b-modal>
      <b-modal id="modal-reopen" hide-footer size="sn" centered :title="$t('Reopen Deal')">
        <div>
          <b-row class="justify-content-center">
            <feather-icon
              icon="AlertCircleIcon"
              size="50"
              class="text-muted"
              color="blue"
            />
          </b-row>
          <b-row class="justify-content-center text-center">
            <b-col>{{$t('Are you sure to proceed')}}?</b-col>
          </b-row>
          <b-row class="justify-content-around" style="margin-top: 15px;">
            <b-button size="sm" variant="danger" @click="cancelReopen()">{{$t('Cancel')}}</b-button>
            <b-button size="sm" variant="success" @click="submitReopen()">{{$t('Confirm')}}</b-button>
          </b-row>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@configs/apis";
import Avatar from "vue-avatar";
import SimpleLoading from "@common-components/simple-loading/SimpleLoading.vue";
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BRow
} from "bootstrap-vue";
import { ArrowRightIcon } from "vue-feather-icons";

export default {
  props: {
    pipelineList: Array,
    stageList: Array,
    userList: Array,
    contactList: Array,
    organizationList: Array,
    tagList: Array,

    dealSelected: Object,

    isPreview: Boolean,
  },
  computed: {
    organization: function() { return this.organizationList.find(o => o.id === this.dealSelected.organization_id) ?? {} },
    contact: function() { return this.contactList.find(c => c.id === this.dealSelected.contact_id) ?? {} },
    creator: function() { return this.userList.find(c => c.id === this.dealSelected.create_user_id) ?? {} },
    owner: function() { return this.userList.find(c => c.id === this.dealSelected.user_id) ?? {} },
  },
  components: {
    Avatar,
    SimpleLoading,
    BBreadcrumb,
    BBreadcrumbItem,
    BContainer,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard, BCardHeader, BCardBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea
  },
  mounted() {
    this.loadHistory();
  },
  data() {
    return {
      histories: [],
      note: "",
      lostReason: "",
      noteType: "NOTE_PHONE",
      lists: [
        { "val": "NOTE_PHONE", "item": "Phone" },
        { "val": "NOTE_EMAIL", "item": "Email" },
        { "val": "NOTE_MEETING", "item": "Meeting" }
      ],
      isLoading: false

    };
  },
  methods: {
    cancelWon: async function() {
      this.$bvModal.hide('modal-won');
    },
    cancelLost: async function() {
      this.$bvModal.hide('modal-lost');
    },
    cancelReopen: async function() {
      this.$bvModal.hide('modal-reopen');
    },
    submitWon: async function() {
      this.isLoading = true;

      this.$bvModal.hide('modal-won');

      await fetcher(apis.crmUpdateDeal + "/" + this.dealSelected.id, FetchMethod.PATCH, {status: 'WON'});

      await this.loadDeal();
      await this.loadHistory();

      this.isLoading = false;
    },
    submitLost: async function() {
      this.isLoading = true;

      this.$bvModal.hide('modal-lost');

      await fetcher(apis.crmUpdateDeal + "/" + this.dealSelected.id, FetchMethod.PATCH, {status: 'LOST', lost_reason: this.lostReason});

      await this.loadDeal();
      await this.loadHistory();

      this.isLoading = false;
    },
    submitReopen: async function() {
      this.isLoading = true;

      this.$bvModal.hide('modal-reopen');

      await fetcher(apis.crmUpdateDeal + "/" + this.dealSelected.id, FetchMethod.PATCH, {status: 'ACTIVE'});

      await this.loadHistory();

      this.isLoading = false;
    },
    submitNote: async function() {
      if (!this.note) return;

      this.isLoading = true;

      await fetcher(apis.crmPostDealHistory + "/" + this.dealSelected.id + "/history", FetchMethod.POST, {message: this.note, type: this.noteType});
      await this.loadHistory();

      this.note = '';

      this.$emit('updated', 'Deal Updated');

      this.isLoading = false;
    },
    loadDeal: async function() {
      // TODO: Do not override prop
      this.dealSelected = await fetcher(apis.crmGetDeals + "/" + this.dealSelected.id, FetchMethod.GET);
    },
    loadHistory: async function() {
      let historiesResponse = await fetcher(apis.crmGetDealHistory + "/" + this.dealSelected.id + "/history", FetchMethod.GET);
      this.histories = historiesResponse.data;
    },
    getUsername: function (userId){
      let user = this.userList.find(c => c.id === userId)
      if (user) return user.name;
      else return null;
    }
  }
};
</script>


<style scoped>
.center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.permission-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-select {
  width: 100%;
}

td {
  padding: 4px;
}

span.note-option {
  padding: 10px;
}

.note-option label {
  font-size: 15px;
  margin-left: 5px;
  color: #ababab;
}

.note-type-container {
  margin: 10px 0;
}
</style>