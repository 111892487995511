<template>
  <div class="main-content">
    <div slot="header" class="clearfix">
      <b-button style="float: left;" class="ml-0" variant="primary" icon="el-icon-plus" v-b-modal.modal-create>{{$t('Create Deal')}}</b-button>

      <b-button style="float: left;" class="ml-1" variant="primary" v-b-modal.modal-createorganization @click="showExport">{{$t('Export')}}</b-button>
      <b-input-group class="input-group-merge ml-1" style="float: left; width: auto;">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" class="text-muted" />
        </b-input-group-prepend>
        <b-form-input placeholder="Search Deals" v-model="searchTerm" v-on:keyup.enter="loadPipelineDeals()"/>
      </b-input-group>

      <b-button style="float: right;" class="ml-1" variant="secondary" @click="resetFilters()"><feather-icon icon="DeleteIcon" /></b-button>
      <b-button style="float: right;" class="ml-1" variant="secondary" @click="loadPipelineDeals()"><feather-icon icon="RefreshCwIcon" /></b-button>

      <!-- Team -->
      <b-dropdown :text="userSelected ? userSelected.user.name : 'All Members'" style="float: right;" class="dropdown"  variant="secondary">
        <b-dropdown-item @click="setUserFilter(null)">{{$t('All Members')}}</b-dropdown-item>
        <b-dropdown-item v-for="item in pipelineUserList" :key="item.user.id" :active="userSelected != null && userSelected === item.id" @click="setUserFilter(item)">{{ item.user.name }}</b-dropdown-item>
      </b-dropdown>

      <!-- State -->
      <b-dropdown :text="stateSelected ? stateSelected.value : 'All Deals'" style="float: right;" class="dropdown" toggle-class="text-decoration-none mr-1">
        <b-dropdown-item v-for="item in stateList" v-if="!item.privileged || isSuperAdmin()" :key="item.value" :active="stateSelected != null && stateSelected === item.key" @click="setStateFilter(item)">{{ item.value }}</b-dropdown-item>
      </b-dropdown>

      <!-- Pipeline -->
      <b-dropdown :text="pipelineSelected?pipelineSelected.name:''" style="float: right;" class="mr-1 dropdown">
        <b-dropdown-item v-for="item in pipelineList" :key="item.value" :active="pipelineSelected != null && pipelineSelected.id === item.id" @click="setPipelineFilter(item)">{{ item.name }}</b-dropdown-item>
      </b-dropdown>

      <div style="float: right; font-size: 18px; line-height: 40px;" v-if="stageList.length > 0">
        <span style="margin-right: 10px">
          {{$t('Total amount')}}:
          {{ getTotal() * 12 | formatTotalPrice }}
          /
          % {{$t('Adjusted')}}:
          {{ getAdjustedTotal() * 12 | formatTotalPrice }}
        </span>

        <el-select v-model="currency" placeholder="" style="width: 100px; margin-right: 8px;">
          <el-option v-for="item in currencyList" :key="item.value.code" :label="item.value.code" :value="item.value.code"> </el-option>
        </el-select>
      </div>
    </div>

    <!-- Render Deal Matrix -->
    <b-row v-loading="loading" class="mt-2">
      <b-col class="stage-col mb-30" style="padding: 0 2px;" v-for="(stage, stageIndex) in stageList" :key="stageIndex">
        <b-card class="bg-light stage-card">
          <div class="stage-header">
            <span class="card-title m-0">
              <div>
                <span class="stage-name">{{ stage.name }}</span>
                <span class="stage-probability text-muted" style="float: right">{{ stage.deal_probability }}%</span>
              </div>
              <span class="text-muted stage-price" v-if="!loading">{{ getStageTotal(stage, stageIndex) * 12 | formatTotalPrice }} x {{ stage.deal_probability }}% = {{ getStageAdjustedTotal(stage, stageIndex) * 12 | formatTotalPrice }} {{ currency }}</span>
            </span>
          </div>

          <draggable :value="dealMatrix[stageIndex]" v-bind="dragOptions" @change="dragChanged(stage, $event)" class="stage-drag">
              <div class="cursor-pointer deal-card" v-for="(deal, dealIndex) in dealMatrix[stageIndex]" :key="dealIndex + stageIndex * 1000" @click="openDeal(deal)" :style="getDealStyle(deal)">
                <div class="d-flex justify-content-between">
                  <div class="deal-header">
                    <div class="deal-name">
                      <feather-icon class="deal-inactive" icon="AlertTriangleIcon" size="16" v-if="isInactive(deal)"/>
                      {{ getOrganization(deal.organization_id).name }}
                      <span v-if="getOrganization(deal.organization_id).status === 'PENDING'">({{ getOrganization(deal.organization_id).status }})</span>
                    </div>
                    <div class="deal-title">{{ deal.title }}</div>
                  </div>
                  <div class="deal-actions d-flex justify-content-between">
                    <a class="deal-edit" href="#" v-on:click.stop="editDeal(deal)" v-if="isDealEditable(pipelineSelected, deal)">
                      <i class="el-icon-edit"></i> <span v-if="false">{{$t('Edit')}}</span>
                    </a>
                    <a class="deal-delete" href="#" v-on:click.stop="deleteDeal(deal)" v-if="isDealSuperEditable(pipelineSelected, deal)">
                      <i class="el-icon-delete"></i> <span v-if="false">{{$t('Delete')}}</span>
                    </a>
                  </div>
                </div>

                <div class="deal-products mt-1" v-if="false">
                  <div class="deal-product" v-for="product in deal.products">
                    <i class="el-icon-box"></i>  {{ product.code }} : {{ product.qty | formatQty }} &times; {{ product.price * 12 | formatUnitPrice }} {{ product.currency }} = {{ (product.price * product.qty * 12) | formatTotalPrice }} {{ product.currency }}
                  </div>
                </div>

                <div class="deal-value mt-1 ul-board--lDesc d-flex align-items-center" v-if="deal.value">
                  <i class="el-icon-wallet"></i>
                  <span class="text-muted" style="margin-left: 5px;" v-if="currency === deal.currency">{{ deal.value * 12 | formatTotalPrice }} {{ deal.currency }}</span>
                  <span class="text-muted" style="margin-left: 5px;" v-if="currency !== deal.currency">{{ getDealTotal(deal) * 12 | formatTotalPrice }} {{ currency }}</span>
                </div>

                <div class="deal-labels mt-1" v-if="deal.tags.length > 0">
                  <span class="deal-label" v-for="tag in deal.tags" :style="{backgroundColor: tag.value.color}">
                    {{ tag.value.locale && tag.value.locale[$i18n.locale] ? tag.value.locale[$i18n.locale] : tag.value.name }}
                  </span>
                </div>
              </div>
          </draggable>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="modal-create" hide-footer size="xl" :title="$t('Create Deal')">
      <create-deal v-on:error="onError" v-on:created="onCreated" :isPreview="isPreview" :pipelineSelected="pipelineSelected" :pipelineList="pipelineList" :stageList="stageList" :userList="userList" :referralUserList="referralUserList" :contactList="contactList" :organizationList="organizationList" :tagList="tagList" :productList="productList" :productTypeList="productTypeList" :currencyList="currencyList" :pipelineUserList="pipelineUserList"></create-deal>
    </b-modal>
    <b-modal id="modal-edit" hide-footer size="xl" :title="$t('Edit Deal')">
      <create-deal v-on:error="onError" v-on:updated="onUpdated" :isPreview="isPreview" :pipelineSelected="pipelineSelected" :pipelineList="pipelineList" :stageList="stageList" :userList="userList" :referralUserList="referralUserList" :contactList="contactList" :organizationList="organizationList" :tagList="tagList" :productList="productList" :productTypeList="productTypeList" :currencyList="currencyList" :pipelineUserList="pipelineUserList" :dealSelected="dealSelected"></create-deal>
    </b-modal>
    <b-modal id="modal-detail" hide-footer size="lg" :title="dealSelected ? dealSelected.title + ' - ' + dealSelected.id : ''">
      <deal-detail v-on:error="onError" v-on:updated="onUpdated" :isPreview="isPreview" :pipelineSelected="pipelineSelected" :pipelineList="pipelineList" :stageList="stageList" :userList="userList" :referralUserList="referralUserList" :contactList="contactList" :organizationList="organizationList" :tagList="tagList" :productList="productList" :productTypeList="productTypeList" :currencyList="currencyList" :pipelineUserList="pipelineUserList" :dealSelected="dealSelected"></deal-detail>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import createDeal from "./DealEdit.vue";
import dealDetail from "./DealDetail.vue";

import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@configs/apis";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { isDealEditable, isDealSuperEditable, isDealVisible, isMemberVisible, isSuperAdmin } from "@/auth/crm";
import moment from "moment";

// transition
export default {
  components: {
    draggable,
    createDeal,
    dealDetail,
    fetcher,
    FetchMethod,
  },
  data() {
    return {
      searchTerm: '',
      currency: "HKD",

      loading: false,
      userList: [],
      referralUserList: [],
      contactList: [],
      organizationList: [],
      tagList: [],
      productList: [],
      productTypeList: [],
      currencyList: [],
      stateList: [{key: "ALL", value: this.$t("All Deals")}, {key: "ACTIVE", value: this.$t("Active Deals")}, {key: "WON", value: this.$t("Won Deals")}, {key: "LOST", value: this.$t("Lost Deals")}, {key: "DELETED", value: this.$t("Deleted Deals"), privileged: true}],

      organizationMap: {},
      currencyMap: {},

      userSelected: null,
      stateSelected: {key: "ACTIVE", value: this.$t("Active Deals")},
      pipelineList: [],
      pipelineSelected: null,
      stageList: [],
      pipelineUserList: [],
      // dealList: [],
      dealSelected: null,

      dealMatrix: [],

      isPreview: false,
    };
  },
  mounted() {
    this.loadUserList();
    this.loadContactList();
    this.loadOrganizationList();
    this.loadPipelineList();
    this.loadProductList();
    this.loadMasterData();
    // this.loadTagList();
    // this.loadCurrencyList();
    // this.loadReferralUserList();
    // this.loadProductTypeList();

  },
  watch:{
       '$i18n.locale'(){
            this.$nextTick(() => {
                this.$forceUpdate()
                this.stateSelected= {key: "ACTIVE", value: this.$t("Active Deals")},
                this.stateList= [{key: "ALL", value: this.$t("All Deals")}, {key: "ACTIVE", value: this.$t("Active Deals")}, {key: "WON", value: this.$t("Won Deals")}, {key: "LOST", value: this.$t("Lost Deals")}, {key: "DELETED", value: this.$t("Deleted Deals"), privileged: true}]
            })
       }
  },
  methods: {
    isDealSuperEditable,
    isDealEditable,
    isDealVisible,
    isSuperAdmin,

    async loadUserList() {
      try {
        const response = await fetcher(
          apis.crmGetUsers,
          FetchMethod.GET,
          {pageSize: 1000000}
        );
        this.userList = Object.freeze(response.data);
      } catch (e) {
        this.showError(e.message);
      }
    },

    async loadContactList() {
      try {
        const response = await fetcher(
          apis.crmGetContact,
          FetchMethod.GET,
          {pageSize: 1000000, sortBy: 'name', sortDirection: 'asc'}
        );
        this.contactList = Object.freeze(response.data);
      } catch (e) {
        this.showError(e.message);
      }
    },

    async loadOrganizationList() {
      try {
        const response = await fetcher(
          apis.crmGetOrganization,
          FetchMethod.GET,
          {pageSize: 1000000, sortBy: 'name', sortDirection: 'asc'}
        );
        this.organizationList = Object.freeze(response.data);

        this.organizationMap = Object.freeze(Object.fromEntries(
          this.organizationList.map(e => [e.id, e])
        ));
      } catch (e) {
        this.showError(e.message);
      }
    },

    async loadMasterData() {
      try {
        const response = await fetcher(
          apis.crmGetMasterData,
          FetchMethod.GET,
          {pageSize: 1000000}
        );

        this.tagList = [];
        this.currencyList = [];
        this.productTypeList = [];
        this.referralUserList = [];

        response.data.forEach(row => {
          if (row.type === 'TAG') this.tagList.push(row);
          if (row.type === 'CURRENCY') this.currencyList.push(row);
          if (row.type === 'PRODUCT_TYPE') this.productTypeList.push(row);
          if (row.type === 'REFERRAL') this.referralUserList.push(row);
        });

        this.tagList = Object.freeze(this.tagList);
        this.currencyList = Object.freeze(this.currencyList);
        this.productTypeList = Object.freeze(this.productTypeList);
        this.referralUserList = Object.freeze(this.referralUserList);

        this.currencyMap = Object.freeze(Object.fromEntries(
          this.currencyList.map(e => [e.value.code, e])
        ));
      } catch (e) {
        this.showError(e.message);
      }
    },

    // Deprecated in favour of loadMasterData()
    async loadTagList() {
      try {
        const response = await fetcher(
          apis.crmGetMasterData,
          FetchMethod.GET,
          {type: 'TAG', pageSize: 1000000, sortBy: 'name', sortDirection: 'asc'}
        );
        this.tagList = response.data;
      } catch (e) {
        this.showError(e.message);
      }
    },

    // Deprecated in favour of loadMasterData()
    async loadCurrencyList() {
      try {
        const response = await fetcher(
          apis.crmGetMasterData,
          FetchMethod.GET,
          {type: 'CURRENCY', pageSize: 1000000, sortBy: 'name', sortDirection: 'asc'}
        );
        this.currencyList = response.data;
      } catch (e) {
        this.showError(e.message);
      }
    },

    // Deprecated in favour of loadMasterData()
    async loadProductTypeList() {
      try {
        const response = await fetcher(
          apis.crmGetMasterData,
          FetchMethod.GET,
          {type: 'PRODUCT_TYPE', pageSize: 1000000, sortBy: 'name', sortDirection: 'asc'}
        );
        this.productTypeList = response.data;
      } catch (e) {
        this.showError(e.message);
      }
    },

    // Deprecated in favour of loadMasterData()
    async loadReferralUserList() {
      try {
        const response = await fetcher(
          apis.crmGetMasterData,
          FetchMethod.GET,
          {type: 'REFERRAL', pageSize: 1000000, sortBy: 'name', sortDirection: 'asc'}
        );
        this.referralUserList = response.data;
      } catch (e) {
        this.showError(e.message);
      }
    },

    async setPipelineFilter(pipeline) {
      this.pipelineSelected = pipeline;
      this.userSelected = null;
      this.stateSelected = {key: "ACTIVE", value: this.$t("Active Deals")};

      await this.loadPipelineDetail(pipeline);
    },

    async setUserFilter(user) {
      this.userSelected = user;
      this.stateSelected = {key: "ACTIVE", value: this.$t("Active Deals")};

      await this.loadPipelineDeals();
    },

    async setStateFilter(state) {
      this.stateSelected = state;

      await this.loadPipelineDeals();
    },

    async resetFilters() {
      this.userSelected = null;
      this.stateSelected = null;

      await this.loadPipelineDeals();
    },

    async loadProductList() {
      try {
        const response = await fetcher(
          apis.crmGetProducts,
          FetchMethod.GET,
          {pageSize: 1000000, sortBy: 'name', sortDirection: 'asc'}
        );
        this.productList = response.data;
      } catch (e) {
        this.showError(e.message);
      }
    },

    async loadPipelineList() {
      try {
        const response = await fetcher(apis.crmGetPipeline, FetchMethod.GET);
        this.pipelineList = response.data;

        // Load detail the first pipeline
        if (!this.pipelineSelected && this.pipelineList.length > 0) {
          await this.loadPipelineDetail(this.pipelineList[0]);
        }
      } catch (e) {
        console.log(e);
      } finally {
        // console.log("finally")
      }
    },

    async loadPipelineDetail(pipeline) {
      this.pipelineSelected = pipeline;

      let startTime = window.performance.now();
      await this.loadPipelineStage(pipeline);
      await this.loadPipelineUser(pipeline);
      await this.loadPipelineDeals(pipeline);
      let endTime = window.performance.now();

      this.showInfo("Pipelines loaded in " + (endTime - startTime).toFixed(0) + " ms");
    },

    async loadPipelineStage(pipeline) {
      // let stageListResponse = await fetcher(apis.crmGetPipeline + "/" + this.pipelineSelected.id + "/stage", FetchMethod.GET);
      // this.stageList = stageListResponse.data;
      this.stageList = pipeline.stages;
    },

    async loadPipelineUser(pipeline) {
      this.pipelineUserList = pipeline.members.filter(member => isMemberVisible(this.pipelineSelected, member));
    },

    async loadPipelineDeals(pipeline) {
      this.loading = true;

      this.dealMatrix = [];

      this.stageList.forEach((stage, stageIndex) => {
        this.dealMatrix.push([]);
      })

      let dealListResponse = await fetcher(apis.crmGetPipeline + "/" + this.pipelineSelected.id + "/deal", FetchMethod.GET, {
        term: this.searchTerm,
        userId: this.userSelected ? this.userSelected.user.id : null,
        status: this.stateSelected ? this.stateSelected.key : null,
        pageSize: 1000000
      });

      let dealList = dealListResponse.data;
      dealList.forEach((deal) => {
        this.stageList.forEach((stage, stageIndex) => {
          if (deal.stage_id === stage.id) {
            this.dealMatrix[stageIndex].push(deal);
          }
        })
      });

      this.dealMatrix = Object.freeze(this.dealMatrix);

      this.loading = false;
    },

    getDealStyle(deal) {
      if (deal.delete_time != null) return {'background-color': '#ffdddd'};
      if (this.getOrganization(deal.organization_id).status === 'PENDING') return {'background-color': '#f0f0f0'};

      return {};
    },

    editDeal(deal) {
      this.dealSelected = deal;
      this.$bvModal.show('modal-edit');
      return false;
    },

    deleteDeal(deal) {
      this.dealSelected = deal;
      this.$confirm('Are you sure to delete Deal "' + deal.title + '"?')
        .then(() => {
          this.loading = true;
          fetcher(apis.crmDeleteDeal + "/" + deal.id, FetchMethod.DELETE).catch(e => {
            this.showError(e.message);
          }).finally(() => {
            this.loading = false;
            this.showInfo('Deal deleted.')
            this.loadPipelineDeals();
          });
        })
        .catch(() => {

        });

      return false;
    },

    openDeal(deal) {
      this.dealSelected = deal;
      this.$bvModal.show('modal-detail');
    },

    getDealTotal(deal) {
      let total = this.getDealTotalInUSD(deal);
      let dashboardCurrency = this.getCurrency(this.currency);

      if (dashboardCurrency) {
        return total / dashboardCurrency.value.rate;
      }
      return 0;
    },

    getDealTotalInUSD(deal) {
      let dealCurrency = this.getCurrency(deal.currency);

      if (dealCurrency) {
        return deal.value * dealCurrency.value.rate;
      }
      return 0;
    },

    getStageTotal(stage, stageIndex) {
      let total = this.getStageTotalInUSD(stage, stageIndex);

      let dashboardCurrency = this.getCurrency(this.currency);

      if (dashboardCurrency) {
        return total / dashboardCurrency.value.rate;
      }
      return 0;
    },

    getStageTotalInUSD(stage, stageIndex) {
      if (!this.dealMatrix[stageIndex]) return 0;

      let total = 0;
      this.dealMatrix[stageIndex].forEach((deal, i) => {
        if (deal.delete_time) return;
        total += this.getDealTotalInUSD(deal);
      });
      return total;
    },

    getStageAdjustedTotal(stage, stageIndex) {
      return this.getStageTotal(stage, stageIndex) * stage.deal_probability / 100;
    },

    getTotal() {
      let total = 0;
      this.dealMatrix.forEach((stage, stageIndex) => {
        total += this.getStageTotal(this.stageList[stageIndex], stageIndex);
      });
      return total;
    },

    getAdjustedTotal() {
      let total = 0;
      this.dealMatrix.forEach((stage, stageIndex) => {
        total += this.getStageAdjustedTotal(this.stageList[stageIndex], stageIndex);
      });
      return total;
    },

    async moveDeal(deal_id, stage_id) {
      this.loading = true;
      await fetcher(apis.crmUpdateDeal + "/" + deal_id, FetchMethod.PATCH, {
        status: "MOVE",
        stage_id: stage_id
      }).catch(e => {
        this.showError(e.message);
      }).finally(() => {
        this.loading = false;
        this.loadPipelineDeals();
      });
    },

    onError(message) {
      this.showError(message);
    },

    onCreated(message) {
      this.showInfo(message);
      this.loadPipelineDetail(this.pipelineSelected);
    },

    onUpdated(message) {
      this.showInfo(message);
      this.loadPipelineDetail(this.pipelineSelected);
    },

    showInfo(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "CheckIcon",
          variant: "success"
        }
      });
    },

    showError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "XIcon",
          variant: "danger"
        }
      });
    },

    showExport: function() {
      window.alert('Please provide export template');
    },

    dragChanged(stage, evt) {
      if (evt.added) {
        console.log('Deal ' + evt.added.element.id + ' moved to stage ' + stage.id + ' index ' + evt.added.newIndex);
        this.moveDeal(evt.added.element.id, stage.id);
      } else if (evt.moved) {
        console.log('Deal ' + evt.moved.element.id + ' ordered in stage ' + stage.id + ' from index ' + evt.moved.oldIndex + ' to ' + evt.moved.newIndex);
        // TODO: Implement ordering?
      }
    },

    isInactive(deal) {
      return moment().diff(deal.update_time) > 30 * 86400 * 1000 && deal.status === 'ACTIVE';
    },

    getOrganization(id) {
      return this.organizationMap[id] ?? {};
    },

    getCurrency(key) {
      return this.currencyMap[key] ?? null;
    },

    moment(time) {
      return moment(time);
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
.filter .btn {
  padding: 8px 10px 0 10px !important;
  color: #6c6b7b !important;
}

.deal-labels {
  text-align: right;
  margin-top: 8px;
}

.deal-label {
  padding: 3px 8px;
  margin-left: 2px;
  background: #ff9f43;
  color: #fff;
  border-radius: 20px;
  font-size: 12px;
}

.stage-col {
  display: flex;
}

.stage-col > * { flex: 1; }

.stage-header {
  position: sticky;
  top: 62px;
  margin: -20px -20px 10px -20px;
  padding: 4px 20px;
  z-index: 1;
  background-color: #f6f6f6;
  border-radius: 0.428rem 0.428rem 0 0;
}

.stage-drag {
  display: flex;
  flex-direction: column;

  margin-right: -1rem;
  margin-left: -1rem;

  flex-grow: 1;
}

.stage-name {
  font-size: 15px;
}

.stage-probability {
  font-size: 14px;
  padding-top: 4px;
}

.stage-price {
  font-size: 15px;
}

.deal-header {
  font-size: 13px;

  word-break: normal;
  word-wrap: anywhere;
}

.deal-name {
  font-weight: bold;
  color: #808080 !important;
  margin-bottom: 0;
}

.deal-edit, .deal-delete {
  display: flex;
  font-size: 12px;
  margin-left: 2px;
}

.deal-inactive {
  margin-bottom: 4px;
  color: #FF8C00;
}

.stage-card > .card-body {
  display: flex;
  flex-direction: column;
}

.deal-card {
  padding: 0.8rem;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 8px;
}

.deal-card:last-child {
  margin-top: 0;
}

.dropdown .dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
